<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Países</h1>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="countries"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
          <template v-slot:[`item.exchangeRate`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!-- <v-text-field
                  v-on="on"
                  style="max-width: 200px"
                  :value="item.exchangeRate"
                /> -->
                <v-chip
                  @click="showExchangeDialog(item)"
                  style="cursor: pointer"
                  v-on="on"
                >
                  {{ item.exchangeRate || 0 }}
                </v-chip>
              </template>
              <span>Clic para editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="exchangeDialog" v-model="exchangeDialog" max-width="500px">
      <edit-exchange-rate
        @cancel="exchangeDialog = false"
        :item="selectedItem"
        @success="handleSuccess"
      ></edit-exchange-rate>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import editExchangeRate from "./edit-exchange-rate";

export default {
  name: "countries",
  components: {
    lottie: Lottie,
    editExchangeRate,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",

      countries: [],
      exchangeDialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Código",
          value: "code",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Tasa de Cambio del dolar",
          value: "exchangeRate",
        },
        {
          text: "Bandera",
          value: "flag",
        },
      ],
    };
  },

  methods: {
    showExchangeDialog(item) {
      this.selectedItem = item;
      this.exchangeDialog = true;
    },
    handleSuccess() {
      this.snackbarText = "Tasa de cambio actualizada exitosamente.";
      this.snackbar = true;
      this.exchangeDialog = false;
      this.selectedItem = null;
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "pais");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    this.$binding(
      "countries",
      db.collection("countries").where("deleted", "==", false)
    ).then((res) => {
      this.loading = false;
    });
    // await db
    //   .collection("countries")
    //   .where("deleted", "==", false)
    //   .get()
    //   .then((response) => {
    //     response.forEach((item) => {
    //       let country = item.data();
    //       country[".key"] = item.id;
    //       this.countries.push(country);
    //     });
    //   });
  },
};
</script>

<style>
.select-city {
  border-radius: 50px;
  padding: 0;
}
</style>
