<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Actualizar tasa de cambio del dolar</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          :loading="loading"
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <input
                class="control-input control-input-number"
                type="number"
                placeholder="Ingrese la tasa de cambio"
                v-model="exchangeRate"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-btn
                @click="update"
                class="save-btn"
                min-height="50px"
                block
                :loading="loading"
                color="primary"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db } from "@/firebase";

export default {
  name: "edit-exchange-rate",
  props: ["item"],
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      country: [],
      exchangeRate: 0,
    };
  },
  async mounted() {
    this.country = Object.assign({}, this.item);
    this.exchangeRate = this.country.exchangeRate;
    this.loading = false;
  },

  methods: {
    update() {
      let exchangeRate = this.exchangeRate ? Number(this.exchangeRate) : 0;

      if (exchangeRate < 0) {
        this.snackbarText =
          "La tasa de cambio no puede ser un número negativo.";
        this.snackbar = true;
        return;
      }

      if (!Number.isNaN(exchangeRate)) {
        this.loading = true;
        db.collection("countries")
          .doc(this.item[".key"])
          .update({
            exchangeRate,
          })
          .then(() => {
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText =
              "Ocurrió un error desconocido, inténtelo nuevamente.";
            this.snackbar = true;
            this.loading = false;
          });
      } else {
        this.snackbarText = "La tasa de cambio debe ser un número válido.";
        this.snackbar = true;
      }
    },
  },
};
</script>
